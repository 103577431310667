'use client'
import { ReactNode, CSSProperties } from 'react';
import { Tooltip as Tool } from 'react-tooltip';

type Placement =
	| 'top'
	| 'top-start'
	| 'top-end'
	| 'right'
	| 'right-start'
	| 'right-end'
	| 'bottom'
	| 'bottom-start'
	| 'bottom-end'
	| 'left'
	| 'left-start'
	| 'left-end';

interface TooltipProps {
	content: ReactNode;
	children: ReactNode;
	placement?: Placement;
	style?: CSSProperties; // <-- Added this for custom styles
	size?: 'small' | 'medium' | 'large';
	html?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({ content, children, placement = 'top', style, size, html = false }) => {
	const generateId = `tooltip-${Math.random().toString(36).substr(2, 9)}`;
	delete style?.border;

	return (
		<div>
			<a className={generateId}>{children}</a>

			<Tool anchorSelect={`.${generateId}`} place={placement} offset={20} style={{ 
				...style,
				zIndex: 1,
			 }}>
				{content}
			</Tool>
		</div>
	);
};

export default Tooltip;
